<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>
		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<v-toolbar>
			<v-container fluid>
				<v-row dense>
					<v-col cols="4">
						<v-select :items="filterPeriodItems" v-model="filterSelectedPeriod" @change="LoadStats">
						</v-select>
					</v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Inizio" v-model="filterCustomStartDate" @input="LoadStats"></DatePicker></v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Fine" v-model="filterCustomEndDate" @input="LoadStats"></DatePicker></v-col>
				</v-row>
			</v-container>
      <v-btn color="primary" @click="downloadCsv"><v-icon left>fas fa-download</v-icon>{{$t("common.download")}} CSV</v-btn>
		</v-toolbar>

		<v-skeleton-loader v-if="LoadingData"></v-skeleton-loader>
		<template v-else>
			<v-card class="elevate-5" v-if="PeriodStats">
				<!-- <v-card-text>
        - 120 questionari
        - 99 verificati / account creato
        - 53 ha risposto via chat
        - 39 prenotato la visita gratuita
        - 22 completato la visita gratuita
        - 16 ha prenotato la visita a pagamento
        - 10 ha completato la visita a pagamento
        - i 10 pazienti hanno fatto totale 2 visite a pagamento
				</v-card-text> -->

        <v-simple-table>
          <tbody>
          <tr v-for="(value, key) in PeriodStats" :key="key">
            <th>{{ key }}</th>
            <td>{{ value }}</td>
          </tr>
          </tbody>
        </v-simple-table>
			</v-card>
		</template>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import DatePicker from "@/components/Shared/FormControl/DatePicker.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {

	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},

	components: { DatePicker },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: false,
					exact: true,
					to: "/Admin/Dashboard",
				}
			],
			LoadingData: false,
			PeriodStats: null,
			filterSelectedPeriod: null,
			filterPeriodItems: [
				{ divider: true },
				{
					text: "Personalizzato",
					value: "CUSTOM"
				},
			],
			filterCustomStartDate: null,
			filterCustomEndDate: null
		};
	},
	created() {
		this.ReportsService = new CrudClient("Reports/PeriodStats");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Dashboard/MonthlySummary",
		},);

		// enumerate months from 06/2023 to today in descending order

		const today = moment();
		const currentMonth = moment("2023-03");

		while (currentMonth.isBefore(today)) {
			this.filterPeriodItems.unshift({
				text: currentMonth.format("MMMM YYYY"),
				value: currentMonth.format("YYYY/MM")
			});
			currentMonth.add(1, "months");
		}

		await this.LoadStats();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getIntervalDates() {
			if (!this.filterSelectedPeriod) {
				return null;
			}

			if (this.filterSelectedPeriod === "CUSTOM") {
				if (this.filterCustomStartDate && this.filterCustomEndDate) {
					return {
						startDate: this.filterCustomStartDate,
						endDate: this.filterCustomEndDate
					}
				}

				return null;
			}
			const period = this.filterSelectedPeriod.split("/");

			const year = parseInt(period[0]);
			const month = parseInt(period[1]);

			let endYear = year;
			let endMonth = month + 1;
			if (endMonth > 12) {
				endMonth = 1;
				endYear++;
			}

			return {
				startDate: period[0] + "-" + period[1] + "-1",
				endDate: endYear + "-" + endMonth + "-1",
			}
		},
		async LoadStats() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				this.$log.debug(interval);
				this.PeriodStats = await this.ReportsService.Get(`ConversionsSummary?startDate=${interval.startDate}&endDate=${interval.endDate}`);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async downloadCsv() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				const csvData = await this.ReportsService.Get(`ConversionsCVS?startDate=${interval.startDate}&endDate=${interval.endDate}`);
				this.$log.debug(csvData);
				const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
				const link = document.createElement("a");
				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", `report_${interval.startDate}_to_${interval.endDate}.csv`);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		}
	}
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Report Mensile"
	}
}
</i18n>
