<template>
  <div>
    <v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
    </v-breadcrumbs>
    <h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>

    <ClientAccountPicker RoleName="Nutritionist" :label="$t('nutriyou.nutritionist')"
                         v-model="nutritionistUserId"
    @change="LoadPatientsList">
    </ClientAccountPicker>

    <h3>
    <UserPublicProfileName :user-id="nutritionistUserId"></UserPublicProfileName></h3>

    <div v-for="patient in PeriodStats" :key="patient.PatientUserId	">

<h4>      <UserPublicProfileName :user-id="patient.PatientUserId"></UserPublicProfileName></h4>
      <br />

      <v-container fluid>
        <v-row>
          <v-col cols="2" v-for="booking in patient.Bookings" :key="booking.BookingId">
            <v-card :color="( booking.BookingTypeId === 1 ) ? 'purple lighten-4' :'nyGreen lighten-1'">
              <v-card-text>
               <span class="text-caption">{{booking.BookingId}}</span>
                <br />
                <span class="text-subtitle-2n">Data visita</span>
                 {{booking.BookingTime | formatDate}}
                <br />
                <span class="text-subtitle-2n">Creato il</span>
                {{booking.CreateDate | formatLocalDate}}
                <br />
                <span class="text-subtitle-2n">Giorni dall'ultima visita:</span>
                <span class="font-weight-bold">{{booking.DaysSinceLastVisit }}</span>
                <br/>
                <span class="text-subtitle-2n">Creata dopo:</span>
                <span class="font-weight-bold">{{booking.CreatedAfter }}</span> gg.
                <br/>
                <nutriYouOrderStatusChip :StatusId="booking.BookingStatusId"></nutriYouOrderStatusChip>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>
<script>
import ClientAccountPicker from "@/components/Account/ClientAccountPicker.vue";
import UserPublicProfileName from "@/components/Shared/UI/UserPublicProfileName.vue";
import CrudClient from "@/services/CrudClient";
// import moment from "moment/moment";
import { mapActions, } from "vuex";
import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip.vue";
import moment from "moment";

export default {
	name: "NutritionistPatientTimeline",
	components: { nutriYouOrderStatusChip, UserPublicProfileName, ClientAccountPicker },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: false,
					exact: true,
					to: "/Admin/Dashboard",
				}
			],
			nutritionistUserId: null,
			PeriodStats: []
		}
	},
	computed: {

	},
	created() {
		this.ReportsService = new CrudClient("Reports/PeriodStats");
	},
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Dashboard/NutritionistPatientTimeline",
		},);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async  LoadPatientsList() {
			if (!this.nutritionistUserId) {
				this.PeriodStats = [];
				return;
			}

			this.PeriodStats = await this.ReportsService.Get(`NutritionistTimelinePatients/${this.nutritionistUserId}`);

			this.PeriodStats.forEach(p => {
				for (let i = 0; i < p.Bookings.length; i++) {
					p.Bookings[i].DaysSinceLastVisit = "-";
					p.Bookings[i].CreatedAfter = "-";

					if (i === 0) { continue; }

					const lastBooking = p.Bookings.findLast(b => b.BookingStatusId === 2 && b.BookingTime < p.Bookings[i].BookingTime)
					this.$log.debug({ lastBooking });

					if (lastBooking) {
						p.Bookings[i].DaysSinceLastVisit = moment(p.Bookings[i].BookingTime).diff(moment(lastBooking.BookingTime), "days")
						p.Bookings[i].CreatedAfter = moment(p.Bookings[i].CreateDate).diff(moment(lastBooking.BookingTime), "days")
					}
				}

				// p.Bookings.forEach(b => {
				// 	b.DaysSinceLastVisit = 19;
				// 	moment();
				// })
			});
		},

		// calculateDaysSinceLastSuccessfulBooking(patientBookings, booking) {
		// 	const previousBooking = patientBookings.findLast(b => b.BookingId < booking.BookingId && b.BookingStatusId === 2);
		//
		// 	this.$log.debug({ previousBooking });
		//
		// 	const daysDifference = previousBooking ? moment(booking.BookingDate).diff(moment(previousBooking.BookingDate), "days") : null;
		//
		// 	this.$log.debug({ daysDifference });
		//
		// 	return daysDifference
		// }
	}
}
</script>

<style scoped lang="scss">

</style>
<i18n>
{
  "it":{
    "pageTitle": "Nutrizionisti - Timeline"
  }
}
</i18n>
