<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>
		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<v-toolbar>
			<v-container fluid>
				<v-row dense>
					<v-col cols="4">
						<v-select :items="filterPeriodItems" v-model="filterSelectedPeriod" @change="LoadStats">
						</v-select>
					</v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Inizio" v-model="filterCustomStartDate" @input="LoadStats"></DatePicker></v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Fine" v-model="filterCustomEndDate" @input="LoadStats"></DatePicker></v-col>
				</v-row>
			</v-container>
      <v-btn color="primary" @click="downloadCsv"><v-icon left>fas fa-download</v-icon>{{$t("common.download")}} CSV</v-btn>

      <v-file-input
        @change="fileUploadSelected"
        accept="text/csv"
        v-model="uploadFiles"
        ref="inputFiles"
      ></v-file-input>
		</v-toolbar>

		<v-skeleton-loader v-if="LoadingData"></v-skeleton-loader>
		<template v-else>
			<v-card class="elevate-5" v-if="PeriodStats">
        <v-simple-table>
          <template v-slot:default>
            <tr v-for="(item, key) in PeriodStats[0]" :key="key"
            :class="{ 'nyGreenLight':  ['VerifiedToLead', 'ChatEngagementToVerified', 'IntroductionVisitToVerified', 'PaidVisitToVerified'].includes(key),
            'nyroseLight':  ['MarketingSpending', 'CostPerLead', 'CostPerVerifiedLead', 'CustomerAcquisitionCost', 'CostPerVisit'].includes(key)}"
            >
              <td class="font-weight-bold pa-2">{{key}}</td>
              <td class="pa-2" v-for="(val, index) in PeriodStats" :key="index">
                <span v-if=' key === "StartDate" || key === "EndDate"'>
                  {{formatDate(val[key])}}</span>
                <span v-else-if="['MarketingSpending', 'CostPerLead', 'CostPerVerifiedLead', 'CustomerAcquisitionCost', 'CostPerVisit'].includes(key)">
                  {{ val[key] | formatCurrency }}
                </span>
                <span v-else>  {{ isFloat( val[key]) ?  numeral(100*val[key]).format("0,0.00") + '%' : val[key]}}</span>

              </td>
            </tr>
          </template>
        </v-simple-table>
			</v-card>
		</template>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import DatePicker from "@/components/Shared/FormControl/DatePicker.vue";
import { mapActions } from "vuex";
import moment from "moment";
import numeral from "numeral";

export default {

	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},

	components: { DatePicker },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: false,
					exact: true,
					to: "/Admin/Dashboard",
				}
			],
			LoadingData: false,
			PeriodStats: null,

			filterSelectedPeriod: null,
			filterPeriodItems: [
				{ divider: true },
				{
					text: "Personalizzato",
					value: "CUSTOM"
				},
			],
			filterCustomStartDate: null,
			filterCustomEndDate: null,

			uploadFiles: null
		};
	},
	created() {
		this.ReportsService = new CrudClient("Reports/PeriodStats");
		this.numeral = numeral;
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Dashboard/ConversionTimeline",
		},);

		const today = moment();
		const currentMonth = moment("2023-03");

		while (currentMonth.isBefore(today)) {
			this.filterPeriodItems.unshift({
				text: currentMonth.format("MMMM YYYY"),
				value: currentMonth.format("YYYY/MM")
			});
			currentMonth.add(1, "months");
		}

		this.filterSelectedPeriod = "CUSTOM";
		this.filterCustomStartDate = moment().subtract(4, "months").format("YYYY-MM-DD");

		this.filterCustomEndDate = moment().subtract(15, "days").format("YYYY-MM-DD");

		await this.LoadStats();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		formatDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},

		getIntervalDates() {
			if (!this.filterSelectedPeriod) {
				return null;
			}

			if (this.filterSelectedPeriod === "CUSTOM") {
				if (this.filterCustomStartDate && this.filterCustomEndDate) {
					return {
						startDate: this.filterCustomStartDate,
						endDate: this.filterCustomEndDate
					}
				}

				return null;
			}
			const period = this.filterSelectedPeriod.split("/");

			const year = parseInt(period[0]);
			const month = parseInt(period[1]);

			let endYear = year;
			let endMonth = month + 1;
			if (endMonth > 12) {
				endMonth = 1;
				endYear++;
			}

			return {
				startDate: period[0] + "-" + period[1] + "-1",
				endDate: endYear + "-" + endMonth + "-1",
			}
		},

		isFloat(n) {
			return typeof n === "number" && Number.isFinite(n) && !Number.isInteger(n);
		},

		async LoadStats() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				this.$log.debug(interval);
				this.PeriodStats = await this.ReportsService.Get(`ConversionsSummaryTimeline?startDate=${interval.startDate}&endDate=${interval.endDate}`);
				this.$log.debug(this.PeriodStats);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async downloadCsv() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				const csvData = await this.ReportsService.Get(`ConversionsSummaryTimelineCsv?startDate=${interval.startDate}&endDate=${interval.endDate}`);
				this.$log.debug(csvData);
				const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
				const link = document.createElement("a");
				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", `report_${interval.startDate}_to_${interval.endDate}.csv`);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async fileUploadSelected(files) {
			await this.ReportsService.UpdateImage(
				null,
				this.uploadFiles.name,
				this.uploadFiles,
				"DailySpending/UploadCsv"
			);

			this.snackSuccess({ Text: this.$t("common.success.fileUploaded") });
			this.uploadFiles = [];
		}
	}
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Dashboard Timeline"
	}
}
</i18n>
